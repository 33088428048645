import React from "react";
import { ActionButton } from "./action-button";
import { SuperBlockContent } from "./block-content/super-block-content";

import ovfx from "../assets/logos/outpost-vfx.png";
import pxl from "../assets/logos/pxl.svg";
import openclassrooms from "../assets/logos/openclassrooms.svg";
import wsp from "../assets/logos/ws_logo.svg";
import dataclay from "../assets/logos/dataclay.png";
import logopackage from "../assets/logos/logo-package.svg";

import "./reviews.scss";
import { CtaFooter } from "./cta-footer";
import { Endorsement } from "../globals";
import { urlFor } from "../utils/utils";

interface Props {
  data: any[];
  featured?: any[];
  endorsements?: Endorsement[];
  className?: string;
  children?: React.ReactNode;
  endorsementsName?: string;
  featuredName?: string;
  hideSuccessStories?: boolean;
  backerMode?: boolean;
}

export const Reviews = ({
  data,
  endorsements,
  featured,
  className,
  children,
  endorsementsName,
  featuredName,
  hideSuccessStories,
  backerMode,
}: Props) => {
  // featured[0].children[0].text?.trim()
  // console.log("featured", featured);
  // const absFeatured = featured?.filter((item: any) => {
  //   const res = item?.children?.filter(
  //     (child: any) => child.text?.trim() !== ""
  //   );
  //   if (res && res.length > 0) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // });
  // console.log("absFeatured", absFeatured);
  return (
    <div
      className={`reviews--main ${
        backerMode ? "reviews--main--backermode" : ""
      } ${className ? className : ""}`}
    >
      {backerMode && endorsements && (
        <>
          <h2 className="subtitle backer-subtitle">BACKERS</h2>

          <div className="reviews--logos">
            {endorsements.map((item, i) => (
              <a
                key={i}
                href={item?.url}
                target="_blank"
                className="logo--link"
              >
                <img
                  key={i}
                  alt={item.name}
                  src={urlFor(item.mainImage?.asset.url).format("webp").url()}
                />
                <div>{item.description.toUpperCase()}</div>
              </a>
            ))}
          </div>
          <p>
            Huge thanks to our backers who have helped make this project
            possible!
          </p>
          <p>
            {
              "If you're interested in supporting this open-source project, please "
            }
            <a href="/contact">contact us.</a>
          </p>
        </>
      )}

      {data && data[0] && data[0] !== null && (
        <>
          <h2 className="subtitle">SUCCESS STORIES</h2>
          {data &&
            data[0].length > 1 &&
            data.map((bodyRaw: any, i: number) => {
              return (
                <SuperBlockContent
                  key={i}
                  blocks={bodyRaw}
                  projectId={"g3so7nt7"}
                  dataset={"production"}
                  className="reviews--grid"
                />
              );
            })}
          {data &&
            data[0].length === 1 &&
            data.map((bodyRaw: any, i: number) => {
              return (
                <div className="reviews--grid--single">
                  <SuperBlockContent
                    key={i}
                    blocks={bodyRaw}
                    projectId={"g3so7nt7"}
                    dataset={"production"}
                    className=""
                  />
                </div>
              );
            })}
        </>
      )}
      {endorsements && !backerMode && (
        <>
          <h2 className="subtitle">
            {endorsementsName ? endorsementsName : "ENDORSEMENTS"}
          </h2>
          <div className="reviews--logos">
            {endorsements.map((item, i) => (
              <img
                key={i}
                alt={item.name}
                src={urlFor(item.mainImage?.asset.url).format("webp").url()}
              />
            ))}
          </div>
        </>
      )}

      {featured && (
        <>
          <h2 className="subtitle">
            {featuredName ? featuredName : "FEATURED IN"}
          </h2>
          <div className="reviews--featured">
            {featured.map((bodyRaw: any, i: number) => {
              return (
                <SuperBlockContent
                  key={i}
                  blocks={bodyRaw}
                  projectId={"g3so7nt7"}
                  dataset={"production"}
                  className="reviews--grid"
                />
              );
            })}
          </div>
        </>
      )}
      <>{children && children}</>
    </div>
  );
};
